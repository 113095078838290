import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`List of the unique viewers for all contents`}</h2>
    <p>{`You can get a list of every individual viewer for all of your channels by
calling this endpoint. You look for a specific viewer by using the
`}<inlineCode parentName="p">{`viewer_identifier`}</inlineCode>{` parameter for matching an identifier or part of an
identifier.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://analytics-api.video.ibm.com/v1/viewers
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`viewer_identifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filter for viewer identifiers containing this string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`list_unfinished_segments`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flag for including unfinished segments in the result (value is true by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 10 by default, max. 10 000)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`data`}</inlineCode>{` key. The elements of the array contain every `}<inlineCode parentName="p">{`viewer`}</inlineCode>{`
matching the query parameters. Each `}<inlineCode parentName="p">{`viewer`}</inlineCode>{` has the following attributes under the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` key:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`last_activity`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last activity recorded from the viewer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`segments`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of the viewers segments, which are matching the query parameters`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`videos_watched`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of distinct medias from the segments array`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`viewer_identifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer identifier string. It can be an e-mail or any string representing a viewer`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`segments`}</inlineCode>{` array contains the following key-value structure:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uniuque segment id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Watched content type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`started_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finished_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish date and time in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start position of the view for videos in seconds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End position of the view for videos in seconds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`view_session_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Player session identifier. Every playing session has a different identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device identifier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`device_info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information parsed from the viewer’s user agent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`country`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The country of the playback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`city`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The city of the playback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`is_mobile_view`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the playback happened on a mobile device the value is true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`view_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onsite`}</inlineCode>{` or `}<inlineCode parentName="td">{`offsite`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "data": [
        {
            "attributes": {
                "last_activity": "2018-07-16T19:20:30+01:00",
                "segments": [
                    {
                        "city": "Budapest",
                        "client_id": "8776986273576598258658",
                        "content_id": 77569623,
                        "content_type": "live",
                        "country": "HU",
                        "finished_at": "2020-07-16T19:20:40+01:00",
                        "from": 0,
                        "id": "4bfc69b5-1320-4a44-a8d6-1fe1f397fb94",
                        "is_mobile_view": false,
                        "started_at": "2020-07-16T19:20:30+01:00",
                        "to": 0,
                        "view_session_id": "7f66d911:8b7aa82c",
                        "view_type": "onsite"
                    }
                ],
                "videos_watched": 10,
                "viewer_identifier": "some.viewer@example.com"
            },
            "type": "Viewer"
        }
    ],
    "pagination": {
        "count": 10,
        "first": {
            "href": "/viewers?_page=1&_limit=1"
        },
        "last": {
            "href": "/viewers?_page=10&_limit=1"
        },
        "next": {
            "href": "/viewers?_page=2&_limit=1"
        },
        "prev": null,
        "self": {
            "href": "/viewers?_page=1&_limit=1"
        }
    }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation of the parameters has failed, the response contains a detailed explanation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`List of the unique viewers for a specific content type`}</h2>
    <p>{`You can get a list of every individual viewer for just a specific content type, such as live or on demand content, by
calling this endpoint. You can also look for a specific viewer by using the
`}<inlineCode parentName="p">{`viewer_identifier`}</inlineCode>{` parameter for matching an identifier or part of an
identifier.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://analytics-api.video.ibm.com/v1/viewers/{content_type}
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The query parameters for the GET request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the content, either `}<inlineCode parentName="td">{`live`}</inlineCode>{` or `}<inlineCode parentName="td">{`recorded`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A list of comma separated IDs`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`viewer_identifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Filter for viewer identifiers containing this string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`list_unfinished_segments`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Flag for including unfinished segments in the result (value is true by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`date_time_to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time for a period in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_page`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page number (value is 1 by default)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_limit`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTIONAL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Requested page size (value is 10 by default, max. 10 000)`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a `}<inlineCode parentName="p">{`data`}</inlineCode>{` key. The elements of the array contain every `}<inlineCode parentName="p">{`viewer`}</inlineCode>{`
matching the query parameters. Each `}<inlineCode parentName="p">{`viewer`}</inlineCode>{` viewer has the following attributes under the `}<inlineCode parentName="p">{`attributes`}</inlineCode>{` key:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`last_activity`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Last activity recorded from the viewer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`segments`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An array of the viewers segments, which are matching the query parameters`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`videos_watched`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of distinct medias from the segments array`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`viewer_identifier`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Viewer identifier string. It can be an e-mail or any string representing a viewer`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<inlineCode parentName="p">{`segments`}</inlineCode>{` array contains the following key-value structure:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Uniuque segment id`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Watched content type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`content_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`started_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`finished_at`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Finish date and time in ISO8601 format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`from`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start position of the view for videos in seconds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`to`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End position of the view for videos in seconds`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`view_session_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Player session identifier. Every playing session has a different identifier.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`client_id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device identifier`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`device_info`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Device information parsed from the viewer’s user agent.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`country`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The country of the playback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`city`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The city of the playback`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`is_mobile_view`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If the playback happened on a mobile device the value is true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`view_type`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onsite`}</inlineCode>{` or `}<inlineCode parentName="td">{`offsite`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "data": [
        {
            "attributes": {
                "last_activity": "2020-07-16T19:20:30+01:00",
                "segments": [
                    {
                        "city": "Budapest",
                        "client_id": "8776986273576598258658",
                        "content_id": 77569623,
                        "content_type": "live",
                        "country": "HU",
                        "finished_at": "2020-07-16T19:20:40+01:00",
                        "from": 0,
                        "id": "4bfc69b5-1320-4a44-a8d6-1fe1f397fb94",
                        "is_mobile_view": false,
                        "started_at": "2020-07-16T19:20:30+01:00",
                        "to": 0,
                        "view_session_id": "7f66d911:8b7aa82c",
                        "view_type": "onsite"
                    }
                ],
                "videos_watched": 10,
                "viewer_identifier": "some.viewer@example.com"
            },
            "type": "Viewer"
        }
    ],
    "pagination": {
        "count": 10,
        "first": {
            "href": "/viewers/live?_page=1&_limit=1"
        },
        "last": {
            "href": "/viewers/live?_page=10&_limit=1"
        },
        "next": {
            "href": "/viewers/live?_page=2&_limit=1"
        },
        "prev": null,
        "self": {
            "href": "/viewers/live?_page=1&_limit=1"
        }
    }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation of the parameters has failed, the response contains a detailed explanation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Resource was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      